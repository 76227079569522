.spinner-container {
  width: 100%;
  height: 100vh;

  .sloy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .loader {
      color: rgba(244, 168, 80, 1);
      position: relative;
      font-size: 16px;
      background: rgba(244, 168, 80, 1);
      animation: escaleY 1s infinite ease-in-out;
      width: 1em;
      height: 4em;
      animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
      content: '';
      position: absolute;
      top: 0;
      left: 2em;
      background: rgba(244, 168, 80, 1);
      width: 1em;
      height: 4em;
      animation: escaleY 1s infinite ease-in-out;
    }
    .loader:before {
      left: -2em;
      animation-delay: -0.32s;
    }
    @keyframes escaleY {
      0%, 80%, 100% {
        box-shadow: 0 0;
        height: 4em;
      }
      40% {
        box-shadow: 0 -2em;
        height: 5em;
      }
    }

  }
}
